import React from 'react'
import { Heading } from 'theme-ui'
import Divider from '@components/Divider'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    background: t => `
      linear-gradient(
        45deg,
        ${t.colors.alpha} 32.5%,
        ${t.colors.betaDark} 50.5%)
    `,
    color: `beta`,
    WebkitBackgroundClip: `text`,
    WebkitTextFillColor: `transparent`,
    fontWeight: `bold`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `normal`,
    maxWidth: [`full`, null, `2/3`]
  }
}

export default () => (
  <>
    <Divider space={3} />
    <Heading variant='h1' sx={styles.heading}>
      Learn more about staying healthy
      <br />
      From the best in the industry
    </Heading>
    <Divider space={3} />
    <Heading variant='h3' sx={styles.running}>
      Learn how to eat a balanced diet, exercise regularly, get enough sleep, 
      avoid smoking and excess alcohol consumption, and practice good hygiene.
      All of these are achiveable if you're willing to put in the time, effort 
      and research.
    </Heading>
  </>
)
